import { z } from 'zod';

/** User DTO schema. */
export const userDtoSchema = z.object({

	/** ID. */
	id: z.number(),

	/** Email. */
	email: z.string(),

	/** Full name. */
	fullname: z.string(),
});

/** User DTO. */
export type UserDto = Readonly<z.infer<typeof userDtoSchema>>;
