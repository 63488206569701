import { Injectable } from '@angular/core';

import { User } from '../models/user';
import { UserDto } from '../dtos/user.dto';

import { MapperFromDto } from './mappers';

/** User mapper. */
@Injectable({
	providedIn: 'root',
})
export class UserMapper implements MapperFromDto<UserDto, User> {
	/** @inheritdoc */
	public fromDto(dto: UserDto): User {
		return {
			email: dto.email,
			fullName: dto.fullname,
			id: dto.id,
		};
	}
}
